/* eslint-disable import/prefer-default-export */
import FuseLoadable from '../@fuse/components/FuseLoadable/FuseLoadable';

const accountRoutes = [
  {
    path: '/account/list',
    exact: true,
    component: FuseLoadable({
      loader: () => import('./content/account/Account'),
    }),
  },
  {
    path: '/account/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/account/AccountEdit'),
    }),
  },
  {
    path: '/account/edit/:accountId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/account/AccountEdit'),
    }),
  },
  {
    path: '/account/import/:accountId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/account/ImportAccount'),
    }),
  },
  {
    path: '/account/export/:accountId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/account/ExportAccount'),
    }),
  },
  {
    path: '/account/options/:id',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/account/AccountOptions'),
    }),
  },
  {
    path: '/account/:accountId/logs',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/account/accountLogs/AccountLogs'),
    }),
  },
  {
    path: '/account/:accountId/mqttCommandsAccount',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () =>
        import('./content/account/mqttCommands/MqttCommandsAccount'),
    }),
  },
];

const scenariosRoutes = [
  {
    path: '/account/:accountId/scenarios/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/scenarios/Scenarios'),
    }),
  },
  {
    path: '/account/:accountId/scenarios/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/scenarios/ScenariosEdit'),
    }),
  },
  {
    path: '/account/:accountId/scenarios/edit/:scenarioId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/scenarios/ScenariosEdit'),
    }),
  },
];

const scenariosSchedulesRoutes = [
  {
    path: '/account/:accountId/scenarios/:scenarioId/scenariosSchedules/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/scenariosSchedules/ScenariosSchedules'),
    }),
  },
  {
    path: '/account/:accountId/scenarios/:scenarioId/scenariosSchedules/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () =>
        import('./content/scenariosSchedules/ScenariosSchedulesEdit'),
    }),
  },
  {
    path: '/account/:accountId/scenarios/:scenarioId/scenariosSchedules/edit/:scenarioSchedulesId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () =>
        import('./content/scenariosSchedules/ScenariosSchedulesEdit'),
    }),
  },
];

const roomsRoutes = [
  {
    path: '/account/:accountId/rooms/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/rooms/RoomsList'),
    }),
  },
  {
    path: '/account/:accountId/rooms/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/rooms/RoomEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/edit/:roomId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/rooms/RoomEdit'),
    }),
  },
];

const devicesRoutes = [
  {
    path: '/account/:accountId/rooms/:roomId/devices/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/devices/DevicesList'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/devices/DevicesEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/edit/:deviceId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/devices/DevicesEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/:deviceMac/logs',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/devices/devicesLogs/DevicesLogs'),
    }),
  },
  {
    path: '/account/:accountId/:deviceType/logs',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/devices/devicesLogs/DevicesLogs'),
    }),
  },
];

const rfControlsRoutes = [
  {
    path: '/account/:accountId/rooms/:roomId/devices/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/devices/DevicesList'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/rfControls/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/rfControls/RfControlsEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/rfControls/edit/:rfControlId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/rfControls/RfControlsEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/lockControls/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/lockControls/LockControlsEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/lockControls/edit/:lockControlId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/lockControls/LockControlsEdit'),
    }),
  },
];

const switchControlsRoutes = [
  {
    path: '/account/:accountId/rooms/:roomId/devices/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/devices/DevicesList'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/switchControls/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/switchControls/SwitchControlsEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/switchControls/edit/:switchControlId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/switchControls/SwitchControlsEdit'),
    }),
  },
];

const smartplugControlsRoutes = [
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/smartplugControls/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/smartplugControls/SmartplugControlsEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/smartplugControls/edit/:smartplugControlId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/smartplugControls/SmartplugControlsEdit'),
    }),
  },
];

const irControlsRoutes = [
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/irControls/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/irControls/IrControlsEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/irControls/edit/:irControlId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/irControls/IrControlsEdit'),
    }),
  },
];

const ledControlsRoutes = [
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/ledControls/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/ledControls/ledControlsEdit'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/ledControls/edit/:ledControlId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/ledControls/ledControlsEdit'),
    }),
  },
];

const shortcutsRoutes = [
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/irControls/:irControlId/shortcuts',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/shortcuts/Shortcuts'),
    }),
  },
  {
    path: '/account/:accountId/rooms/:roomId/devices/:deviceId/irControls/:irControlId/shortcuts/edit/shortcutId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/shortcuts/Shortcuts'),
    }),
  },
];

const accountsUsersRoutes = [
  {
    path: '/accountsUsers/:accountId/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/accountsUsers/AccountsUsers'),
    }),
  },
  {
    path: '/accountsUsers/:accountId/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/accountsUsers/AccountsUsersEdit'),
    }),
  },
  {
    path: '/accountsUsers/:accountId/edit/:accountUserId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/accountsUsers/AccountsUsersEdit'),
    }),
  },
  {
    path: '/accountsUsers/:accountId/permissions/:accountUserId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/accountsUsers/permissions/Permissions'),
    }),
  },
];

const usersRoutes = [
  {
    path: '/users/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/users/Users'),
    }),
  },
  {
    path: '/users/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/users/UsersEdit'),
    }),
  },
  {
    path: '/users/edit/:userId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/users/UsersEdit'),
    }),
  },
];

const firmwareReleasesRoutes = [
  {
    path: '/firmwareReleases/list',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/firmwareReleases/FirmwareReleases'),
    }),
  },
  {
    path: '/firmwareReleases/create',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/firmwareReleases/FirmwareReleasesEdit'),
    }),
  },
  {
    path: '/firmwareReleases/edit/:firmwareReleaseId',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/firmwareReleases/FirmwareReleasesEdit'),
    }),
  },
];

const firmwareVersionsRoutes = [
  {
    path: '/firmwareVersions/list',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/firmwareVersions/FirmwareVersions'),
    }),
  },
  {
    path: '/firmwareVersions/create',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/firmwareVersions/FirmwareVersionsEdit'),
    }),
  },
  {
    path: '/firmwareVersions/edit/:firmwareVersionId',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/firmwareVersions/FirmwareVersionsEdit'),
    }),
  },
];

const retailersRoutes = [
  {
    path: '/retailers/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/retailers/Retailers'),
    }),
  },
  {
    path: '/retailers/create',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/retailers/RetailersEdit'),
    }),
  },
  {
    path: '/retailers/edit/:retailerId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/retailers/RetailersEdit'),
    }),
  },
];

const irModelsRoutes = [
  {
    path: '/irModels/list',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/irModels/IrModels'),
    }),
  },
  {
    path: '/irModels/create',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/irModels/IrModelsEdit'),
    }),
  },
  {
    path: '/irModels/edit/:irModelId',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/irModels/IrModelsEdit'),
    }),
  },
];

const commandsRoutes = [
  {
    path: '/irModels/:irModelId/commands/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/commands/Commands'),
    }),
  },
  {
    path: '/irModels/:irModelId/commands/edit/:commandId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/commands/Commands'),
    }),
  },
];

const channelImagesRoutes = [
  {
    path: '/channelImages/list',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/channelImages/ChannelImages'),
    }),
  },
  {
    path: '/channelImages/edit/:channelImageId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/channelImages/ChannelImages'),
    }),
  },
];

const centralLoginsRoutes = [
  {
    path: '/centralLogins/:accountId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/account/centralLogins/CentralLogins'),
    }),
  },
];

const firmwareUpdatesRoutes = [
  {
    path: '/firmwareUpdates/:accountId',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () => import('./content/firmwareUpdates/FirmwareUpdates'),
    }),
  },
  {
    path: '/firmwareUpdates/:accountId/device/:mac/logs',
    exact: true,
    auth: ['admin', 'retailer'],
    component: FuseLoadable({
      loader: () =>
        import(
          './content/firmwareUpdates/firmwareUpdatesDeviceLogs/FirmwareUpdatesLogList'
        ),
    }),
  },
];

const deviceRegistrations = [
  {
    path: '/deviceRegistrations/list',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/deviceRegistrations/DeviceRegistrations'),
    }),
  },
  {
    path: '/deviceRegistrations/edit/:deviceRegistrationId',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () =>
        import('./content/deviceRegistrations/DeviceRegistrationsEdit'),
    }),
  },
];

const deviceTypesRoutes = [
  {
    path: '/deviceTypes/list',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/deviceTypes/DeviceTypes'),
    }),
  },
  {
    path: '/deviceTypes/create',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/deviceTypes/DeviceTypesEdit'),
    }),
  },
  {
    path: '/deviceTypes/edit/:deviceTypeId',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/deviceTypes/DeviceTypesEdit'),
    }),
  },
];

const userActionLogsRoutes = [
  {
    path: '/userActionLogs/list',
    exact: true,
    auth: ['admin'],
    component: FuseLoadable({
      loader: () => import('./content/userActionLogs/UserActionLogs'),
    }),
  },
];

export const RoutesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    ...accountRoutes,
    ...scenariosRoutes,
    ...roomsRoutes,
    ...devicesRoutes,
    ...accountsUsersRoutes,
    ...rfControlsRoutes,
    ...switchControlsRoutes,
    ...irModelsRoutes,
    ...commandsRoutes,
    ...irControlsRoutes,
    ...scenariosSchedulesRoutes,
    ...shortcutsRoutes,
    ...usersRoutes,
    ...retailersRoutes,
    ...channelImagesRoutes,
    ...centralLoginsRoutes,
    ...firmwareReleasesRoutes,
    ...firmwareVersionsRoutes,
    ...firmwareUpdatesRoutes,
    ...smartplugControlsRoutes,
    ...deviceRegistrations,
    ...deviceTypesRoutes,
    ...userActionLogsRoutes,
    ...ledControlsRoutes,
    {
      path: '/users/list',
      exact: true,
      auth: ['admin'],
      component: FuseLoadable({
        loader: () => import('./content/users/Users'),
      }),
    },
  ],
};

/**
 * Lazy load Example
 */
/*
import FuseLoadable from '@fuse/components/FuseLoadable/FuseLoadable';

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: FuseLoadable({
                loader: () => import('./Example')
            })
        }
    ]
};
*/
