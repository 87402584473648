/* eslint-disable import/prefer-default-export */
export const fuseNavigationConfig = [
  {
    id: 'automation',
    title: 'Automação',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'account-component',
        title: 'Conta',
        type: 'item',
        icon: 'home',
        url: '/account/list',
        auth: ['admin', 'retailer'],
      },
      {
        id: 'users-component',
        title: 'Usuários',
        type: 'item',
        icon: 'person',
        url: '/users/list',
        auth: ['admin'],
      },
      {
        id: 'retailers-component',
        title: 'Revendas',
        type: 'item',
        icon: 'store',
        url: '/retailers/list',
        auth: ['admin'],
      },
      {
        id: 'ir-models-component',
        title: 'Controles IR',
        type: 'item',
        icon: 'dialpad',
        url: '/irModels/list',
        auth: ['admin'],
      },
      {
        id: 'firmware-releases',
        title: 'Versões de Firmware',
        type: 'item',
        icon: 'ballotOutlined',
        url: '/firmwareReleases/list',
        auth: ['admin'],
      },
      {
        id: 'firmware-versions',
        title: 'Firmwares',
        type: 'item',
        icon: 'ballotOutlined',
        url: '/firmwareVersions/list',
        auth: ['admin'],
      },
      {
        id: 'device-registrations',
        title: ' Dispositivos',
        type: 'item',
        icon: 'devicesOtherOutlined',
        url: '/deviceRegistrations/list',
        auth: ['admin'],
      },
      {
        id: 'device-types',
        title: 'Tipos de Dispositivo',
        type: 'item',
        icon: 'devicesOtherOutlined',
        url: '/deviceTypes/list',
        auth: ['admin'],
      },
      {
        'id': 'UserActionLogs',
        'title': 'Logs de Ações de Usuários',
        'type' : 'item',
        'icon' : 'apps',
        'url'  : '/userActionLogs/list',
        'auth' : ['admin']
      },
      // {
      //   'id': 'channel-images-component',
      //   'title': 'Logo Canais',
      //   'type': 'item',
      //   'icon': 'person',
      //   'url': '/channelImages/list',
      //   'auth': ['admin']
      // }
    ],
  },
];
