/* eslint-disable import/no-cycle */
import { setUserData, logoutUser } from './user.actions';
import { app } from '../../../main/api/feathers';
import { appLogs } from '../../../main/api/feathersLogs';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_OUT = 'LOGIN_OUT';

export function submitLogin({ email, password }) {
  return (dispatch) =>
    app
      .authenticate({
        strategy: 'dashboard',
        email,
        password,
      })
      .then((response) => {
        console.log('response', response);
        const { user, accessToken } = response || {};

        appLogs.authenticate({ strategy: 'jwt', accessToken });
        return app.service('users').get(user.id);
      })
      .then((user) => {
        dispatch(setUserData(user));
        dispatch({
          type: LOGIN_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_ERROR,
          payload: { ...error, email },
        });
      });
}

export function loginOut() {
  return (dispatch) => {
    dispatch({
      type: LOGIN_OUT,
    });
  };
}

export function tokenValidate() {
  console.log('chamou token validate');
  return (dispatch) =>
    app
      .reAuthenticate()
      .then((response) => {
        const { user, accessToken } = response;
        // show application page
        appLogs.authenticate({ strategy: 'jwt', accessToken });
        if (user) {
          console.log('validate user', user);
          dispatch(setUserData(user));
          return dispatch({
            type: LOGIN_SUCCESS,
          });
        }
        return dispatch({
          type: LOGIN_ERROR,
        });
      })
      .catch((error) => {
        // show login page
        console.log('error', error);
        dispatch(logoutUser());
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
}
