import { services } from '../../../../api/feathers';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const ADD_ACCOUNTS = 'ADD_ACCOUNTS';
export const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS';
export const REMOVE_ACCOUNTS = 'REMOVE_ACCOUNTS';
export const REMOTE_ACCOUNT = 'REMOTE_ACCOUNT';

export function getAccounts(query = {}) {
  return (dispatch) =>
    services.accounts.find(query).then((response) =>
      dispatch({
        type: GET_ACCOUNTS,
        payload: response.data,
      })
    );
}

export function getAccount(id) {
  return (dispatch) =>
    services.accounts.get(id).then((response) =>
      dispatch({
        type: GET_ACCOUNT,
        payload: response,
      })
    );
}

export function addAccounts(account) {
  return (dispatch) =>
    services.accounts.create(account).then((response) =>
      dispatch({
        type: ADD_ACCOUNTS,
        payload: response,
      })
    );
}

export function updateAccounts(id, account) {
  return (dispatch) =>
    services.accounts.patch(id, account).then((response) =>
      dispatch({
        type: UPDATE_ACCOUNTS,
        payload: response,
      })
    );
}

export function removeAccounts(id) {
  return (dispatch) =>
    services.accounts.remove(id).then((response) =>
      dispatch({
        type: REMOVE_ACCOUNTS,
        payload: response,
      })
    );
}
export function addRemoteAccess(deviceId) {
  return (dispatch) =>
    services.remoteAccess.create(deviceId).then((response) =>
      dispatch({
        type: REMOTE_ACCOUNT,
        payload: response,
      })
    );
}
