import { servicesLogs, appLogs, socket } from '../../../../api/feathersLogs';

export const GET_USAGE_LOGS = 'GET_USAGE_LOGS';
export const UPDATE_USAGE_LOGS = 'UPDATE_USAGE_LOGS';
export const LIVE_USAGE_LOGS_ENABLED = 'LIVE_USAGE_LOGS_ENABLED';

export function getUsageLogs(query) {
  return (dispatch) =>
    servicesLogs.usageLogs.find(query).then((response) =>
      dispatch({
        type: GET_USAGE_LOGS,
        payload: response.data,
      })
    );
}

export function startLiveUsageLogs(accountId) {
  // console.log('startLiveUsageLogs')
  // socket.emit('subscribe', accountId);
  // appLogs.service('usage-logs').emit('create')
  // socket.emit('patched', 'usage-logs', { accountId }, (error, data) => {
  //   console.log('Found all messages', data);
  // });
  socket.emit('create', 'live-subscribes', { accountId }, (error, request) => {
    if (!request) {
      console.log('ERRO AO CRIAR SUBSCRIBE', error);
    } else {
      console.log('SUCESSO AO CRIAR SUBSCRIBE', request);
    }
  });
  return (dispatch) =>
    appLogs.service('usage-logs').on('patched', (data) => {
      console.log('appLogs.service usage-log ', data);
      return dispatch({
        type: UPDATE_USAGE_LOGS,
        payload: data,
      });
    });
}

export function stopLiveUsageLogs() {
  // console.log('stopLiveUsageLogs')
  // socket.emit('unsubscribe', accountId)
  socket.emit(
    'create',
    'live-subscribes',
    { accountId: null },
    (error, request) => {
      if (!request) {
        console.log('ERRO AO CRIAR UNSUBSCRIBE', error);
      } else {
        console.log('SUCESSO AO CRIAR UNSUBSCRIBE', request);
      }
    }
  );
  appLogs.service('usage-logs').off('patched');
  return (dispatch) =>
    dispatch({
      type: LIVE_USAGE_LOGS_ENABLED,
      payload: false,
    });
}
