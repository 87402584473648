import React from 'react'
import {withStyles} from '@material-ui/core/styles/index';
import {Card, CardContent, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { FuseAnimate } from '../../../@fuse';
import RecoverPasswordForm from './RecoverPasswordForm';

const styles = () => ({
	root : {
		background    : "url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat",
		backgroundSize: 'cover'
	},
	intro: {
		color: '#ffffff'
	},
	card : {
		// width   : '100%',
		width: 400
	}
});

// const RecoverePassword = function (props) {
//   return <div />;
// };

// const RecoverePassword = function (props) {
//   return <div />;
// };

const RecoverePassword = function (props) {
	const { classes } = props;
	return (
		<div 
			style={{ margin: '0 auto' }} 
			className={classNames(classes.root, "flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0")}
		>
			<FuseAnimate>
				<Card className={classNames(classes.card, "mx-auto m-16 md:m-0")}>
					<CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
						<Typography variant="h6" className="text-center md:w-full mb-48">CADASTRE SUA SENHA</Typography>
						<RecoverPasswordForm />
					</CardContent>
				</Card>
			</FuseAnimate>
		</div>
	)
}




export default withStyles(styles, {withTheme: true})(RecoverePassword);
