import * as Actions from '../actions/commands.actions';

const initialState = [];

const commands = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_COMMANDS:
      return action.payload;

    case Actions.ADD_COMMANDS:
      return [...state, action.payload];

    case Actions.UPDATE_COMMANDS:
    case Actions.GET_COMMAND: {
      const index = state.findIndex(
        (command) => command.id === action.payload.id
      );
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }

    default:
      return state;
  }
};

export default commands;
