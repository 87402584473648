/* eslint-disable */
import { services } from '../../../../api/feathers';

export const GET_LED_CONTROLS = 'GET_LED_CONTROLS';
export const GET_LED_CONTROL = 'GET_LED_CONTROL';
export const ADD_LED_CONTROLS = 'ADD_LED_CONTROLS';
export const UPDATE_LED_CONTROLS = 'UPDATE_LED_CONTROLS';
export const REMOVE_LED_CONTROLS = 'REMOVE_LED_CONTROLS';

export function getLedControls(query = {}) {
  return (dispatch) =>
  services.ledControls
  .find({
        query
      })
      .then((response) => {
        dispatch({
          type: GET_LED_CONTROLS,
          payload: response.data,
        })}
        );
      }
      
      export function getLedControl(id, query = {}) {
  return (dispatch) =>
    services.ledControls
      .get(id, query)
      .then((response) => {
        return dispatch({
          type: GET_LED_CONTROL,
          payload: response,
        })}
      ).catch((error) => console.log('getControlError',error));
}

export function addLedControls(ledControl) {
  return (dispatch) =>
    services.ledControls
      .create(ledControl)
      .then((response) =>
        dispatch({
          type: ADD_LED_CONTROLS,
          payload: response,
        })
      )
      .then(() => dispatch(getLedControls(ledControl.deviceId)));
}

export function updateLedControls(id, ledControl) {
  return (dispatch) =>
    services.ledControls
      .patch(id, ledControl)
      .then((response) =>
        dispatch({
          type: UPDATE_LED_CONTROLS,
          payload: response,
        })
      );
}

export function removeLedControls(id) {
  return (dispatch) =>
    services.ledControls.remove(id).then((response) =>
      dispatch({
        type: REMOVE_LED_CONTROLS,
        payload: response,
      })
    );
}
