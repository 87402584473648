import * as Action from '../actions/usageLogs.actions';

const initialState = [];

const usageLogs = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_USAGE_LOGS:
      return action.payload;
    case Action.UPDATE_USAGE_LOGS: {
      const index = state.findIndex((log) => log.uuid === action.payload.uuid);
      const newState = state;
      newState[index > -1 ? index : state.length] = action.payload;
      return [...newState];
    }

    default:
      return state;
  }
};

export default usageLogs;
