import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { withStyles, Button, InputAdornment, Icon, Box } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { TextFieldFormsy } from '../../../../@fuse';
import * as Actions from '../../../../auth/store/actions';
import history from '../../../../history';
import '../../../../styles/customizations.css';
import packageJson from '../../../../../package.json';

const styles = () => ({
  root: {
    width: '100%',
  },
});

class JWTLoginTab extends Component {
  form = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      validationErrors: null,
    };
  }

  componentDidUpdate() {
    const { login } = this.props;
    if (login.error && (login.error.email || login.error.password)) {
      this.form.updateInputsWithError({
        email: 'Usuário ou senha invalida!',
      });

      this.setState({
        validationErrors: 'Usuário ou senha invalida!',
      });

      login.error = null;
      this.enableButton();
    }
    if (login.success) {
      history.push({
        pathname: '/account/list',
      });
    }

    return null;
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  onSubmit = (model) => {
    const { submitLogin } = this.props;
    const { email, password } = model;

    if (!email) {
      this.form.updateInputsWithError({
        email: 'Informe a senha!',
      });
      return false;
    }

    if (!password) {
      this.form.updateInputsWithError({
        password: 'Informe o usuário!',
      });
      return false;
    }

    submitLogin(model);
    this.disableButton();
    return true;
  };

  render() {
    const { classes } = this.props;
    const { canSubmit, validationErrors } = this.state;
    const { version } = packageJson;
    return (
      <div className={classes.root}>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          onChange={() => this.setState({ validationErrors: null })}
          ref={(form) => {
            this.form = form;
          }}
          className="flex flex-col justify-center w-full"
        >
          <TextFieldFormsy
            className="login-input"
            type="text"
            name="email"
            label="Username/Email"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className="text-20" color="action">
                    email
                  </Icon>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            required
            style={{ marginDown: '15px' }}
          />

          <TextFieldFormsy
            className="login-input"
            type="password"
            name="password"
            label="Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className="text-20" color="action">
                    vpn_key
                  </Icon>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            required
          />
          {validationErrors && (
            <div style={{ color: 'red' }}>{validationErrors}</div>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="w-full mx-auto mt-16 normal-case"
            aria-label="LOG IN"
            disabled={!canSubmit}
            style={{ marginTop: '10px', marginBotton: '10px' }}
            value="legacy"
          >
            Login
          </Button>
          <a
            style={{ marginTop: '10px', marginBotton: '10px' }}
            href="https://api.smarteklife.com/oauth/amazon"
            target="_blank"
            rel="noreferrer"
          >
            Login Amazon
          </a>
        </Formsy>
        <Box style={{ marginTop: 50, textAlign: 'center'}}>
            <span style={{ fontSize: '12px' }}>Versão: {version}</span>
        </Box>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLogin: Actions.submitLogin,
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    login: auth.login,
    user: auth.user,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(JWTLoginTab))
);
