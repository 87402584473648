import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';

import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import LOGS_URL from './logsUrl';

const socket = io(LOGS_URL);
const appLogs = feathers();
appLogs.configure(socketio(socket));
appLogs.configure(auth({ storage: window.localStorage }));

const servicesLogs = {
  usageLogs: appLogs.service('usage-logs'),
};
export { appLogs, servicesLogs, socket };
