import { services } from '../../../../api/feathers';

export const GET_COMMANDS = 'GET_COMMANDS';
export const GET_COMMAND = 'GET_COMMAND';
export const ADD_COMMANDS = 'ADD_COMMANDS';
export const UPDATE_COMMANDS = 'UPDATE_COMMANDS';

export function getCommands(irModelId) {
  return (dispatch) =>
    services.commands
      .find({ query: { irModelId, $sort: { name: 1 } } })
      .then((response) =>
        dispatch({
          type: GET_COMMANDS,
          payload: response.data,
        })
      );
}

export function getCommand(id) {
  return (dispatch) =>
    services.commands.get(id).then((response) =>
      dispatch({
        type: GET_COMMAND,
        payload: response,
      })
    );
}

export function addCommands(command) {
  return (dispatch) =>
    services.commands.create(command).then((response) =>
      dispatch({
        type: ADD_COMMANDS,
        payload: response,
      })
    );
}

export function updateCommands(id, command) {
  return (dispatch) =>
    services.commands.patch(id, command).then((response) =>
      dispatch({
        type: UPDATE_COMMANDS,
        payload: response,
      })
    );
}
